<template>
  <a-modal title='批量生成' :width='800' :visible='visible' :confirmLoading='confirmLoading' @ok='handleOk' @cancel='close'
    cancelText='关闭'>

    <a-spin :spinning='confirmLoading'>
      <a-form>
        <a-form-item label="生成数量" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number style='width: 180px' v-model='formData.bathNum' placeholder='请输入生成数量(10000)' :min='1' :maxLength='10' :max='10000'/>
        </a-form-item>

        <a-form-item  label="会员时长" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input-number style='width: 180px' v-model='formData.month' placeholder='请输入会员时长（月）' :min='1' :maxLength='10' :max='1200'/>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>

import ApiSelect from '@/components/crud/form/ApiSelect.vue'
import { bathCoupon } from '@/api/coupon/coupon'

export default {
  name: 'CrudForm',
  components: {
    ApiSelect
  },
  props: {
    statusOptions: {
      type: Array,
      default() {
        return [{ id: 2, name: '审核通过' }, { id: 1, name: '审核驳回' }];
      }
    }
  },
  data() {
    return {
      formData: {
        bathNum: null,
        month:null
      },           // 表单数据
      visible: false,         // 弹窗显示控制
      confirmLoading: false,  // 提交后加载, 防止短时间内重复提交

      labelCol: { xs: { span: 24 }, sm: { span: 5 } },    // 表单项标签宽度配置
      wrapperCol: { xs: { span: 24 }, sm: { span: 16 } }, // 表单项内容宽度配置
    }
  },
  methods: {
    /**
     * 外部方法, 外部调用此方法 打开编辑表单
     */
    audit() {
      this.formData.bathNum = null;
      this.formData.month = 1;
      this.confirmLoading = false;
      this.visible = true;
    },

    /**
     * 关闭表单, 触发 close 事件
     */
    close() {
      this.$emit('close')
      this.visible = false
    },
    /**
     * 提交
     */
    handleOk() {
      this.confirmLoading = true;
      if (!this.formData.bathNum) {
        this.$message.warn("请输入生成数量");
        this.confirmLoading = false;
        return;
      }
      if (!this.formData.month) {
        this.$message.warn("请输入会员时长");
        this.confirmLoading = false;
        return;
      }
      bathCoupon(this.formData).then(res => {
        if (res.success) {
          this.$message.success(res.message);
          this.close()
          this.$emit('ok');
        } else {
          this.$message.warning(res.message)
          this.close()
          this.$emit('cancel');
        }

      }).finally(() => {
        this.submitLoading = false
      })
    },
  }
}
</script>

<style lang='less' scoped></style>